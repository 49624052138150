import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Unearthed Arcana</h2>
    <h3>ENVIRONMENTAL RACIAL VARIANTS</h3>
    <table className="monster-index" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td valign="top">
            <b><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#aquatic-races">Aquatic Races</a></b>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-dwarves">Aquatic Dwarves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-elves">Aquatic Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-gnomes">Aquatic Gnomes</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-goblins">Aquatic Goblins</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-half-elves">Aquatic Half-Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-half-orcs">Aquatic Half-Orcs</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-halflings">Aquatic Halflings</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-humans">Aquatic Humans</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-kobolds">Aquatic Kobolds</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#aquatic-orcs">Aquatic Orcs</a>
          </td>
          <td valign="top">
            <b><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#arctic-races">Arctic Races</a></b>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-dwarves">Arctic Dwarves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-elves">Arctic Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-gnomes">Arctic Gnomes</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-goblins">Arctic Goblins</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-half-elves">Arctic Half-Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-half-orcs">Arctic Half-Orcs</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-halflings">Arctic Halflings</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-kobolds">Arctic Kobolds</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#arctic-orcs">Arctic Orcs</a>
          </td>
          <td valign="top">
            <b><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#desert-races">Desert Races</a></b>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-dwarves">Desert Dwarves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-elves">Desert Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-gnomes">Desert Gnomes</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-goblins">Desert Goblins</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-half-elves">Desert Half-Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-half-orcs">Desert Half-Orcs</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-halflings">Desert Halflings</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-kobolds">Desert Kobolds</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#desert-orcs">Desert Orcs</a>
          </td>
          <td valign="top">
            <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79137.jpg"><img className="art-wide" src="pict.jpg" alt="pict" style={{
                "border": "0px solid",
                "width": "73px",
                "height": "75px"
              }} /></a><b><a style={{
                "color": "rgb(87, 158, 182)"
              }} href="#jungle-races">Jungle Races</a></b>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-dwarves">Jungle Dwarves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-elves">Jungle Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-gnomes">Jungle Gnomes</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-goblins">Jungle Goblins</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-half-elves">Jungle Half-Elves</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-half-orcs">Jungle Half-Orcs</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-halflings">Jungle Halflings</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-kobolds">Jungle Kobolds</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jungle-orcs">Jungle Orcs</a>
          </td>
        </tr>
      </tbody>
    </table>
Racial variants are a great way add diversity to your game without
drastically changing the ecology of your world. One method of altering
the existing races is to introduce environmental variants, a number of
which are presented here.
    <p> You may decide that one or more of these variants represent
the &ldquo;standard&rdquo; version of a given race in your world. For
stance, in a desert-based campaign the desert races presented below
could replace the normal versions of the races.
    </p>
    <p> Alternatively, these variants could coexist with the standard
races (or even with other variants) in your world. For example, you can
use them as world-building tools&mdash;the existence of racial
offshoots may constitute living proof of an ancient racial migration in
response to some disaster.
    </p>
    <p> Each racial variant modifies the race to which it is applied
(hereafter called the standard race) in minor ways. All racial traits
of the standard race &mdash; racial skill bonuses, bonus feats, special
sensory capabilities (such as darkvision and low-light vision), ability
modifiers, combat bonuses against specific foes, and racial weapon
proficiencies &mdash; are retained unless the variant specifies
otherwise. For instance, a human retains his extra skill points and
extra feat at 1st level, a dwarf retains his stonecunning ability, and
an elf retains her ability to spot secret doors, unless the variant
description specifically indicates otherwise.
    </p>
    <p> Many of the variant races described in this section provide
alternate ability score adjustments. In these cases, the adjustments
given here supersede the standard race's adjustments. For example, the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#aquatic-goblin">aquatic goblin's</a> ability score adjustments are -2 Strength, +2 Constitution, and -2 Charisma. These adjustments take the place of the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="monstersG.html#goblin">goblin's</a> normal -2 Strength, +2 Dexterity, and -2 Charisma.
    </p>
    <p> Because humans are, by nature, the most adaptable of races,
environmental variants are generally not included for humans. The
exception is <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#aquatic-humans">aquatic humans</a>,
which, due to their ability to live and breathe underwater, are
sufficiently different from other humans to warrant an environmental
variant.
    </p>
    <h2><a id="aquatic-races"></a>Aquatic Races</h2>
Beneath the surface of the ocean lies an exotic and a alien world,
complete with canyons, mountains, volcanoes, and forests. Inky depths
conceal sunken cities, sleeping deities, and titanic monsters. For
residents of the aquatic realm, the sunny world of the air is as
mysterious and unknown as the black depths of the sea.
    <p> Players wanting their characters to be ocean dwellers can use
these variants. Or, their characters might encounter these races while
journeying beneath the sea.
    </p>
    <p>
The following cultural attributes are common to most aquatic races.
    </p>
    <h6>Personality</h6>
An aquatic race usually shares the temperament and interests of the
standard race, but its members tend to be more serene than their
landborn cousins.
    <h6>Physical Description</h6>
A water creature is taller and thinner than a standard member of her
race. Her feet and hands are webbed to facilitate swimming. (<a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-elves">Aquatic elves</a>
even have fins along their limbs that serve the same purpose.) A water
creature's skin ranges from pale green to dark blue. Her hair usually
matches her skin color but may be one or two shades darker.
    <p> Water dwellers wear very little clothing, and what they do
wear tends to cling tightly to their bodies. Some favor jewelry made of
coral and other undersea treasures.
    </p>
    <h6>Relations</h6>
The attitudes of water creatures tend to be very similar to those of
their landborn cousins. Members of aquatic races generally treat
landborn creatures with guarded pleasantness on the rare occasions when
they meet.
    <h6>Alignment</h6>
Having avoided most of the political, economic, and military turmoil of
the surface world, water creatures tend to take a much more placid view
of life than their landborn cousins do. Members of an aquatic race
favor neutrality in at least one aspect of alignment, if not both.
    <h6>Lands</h6>
The civilizations of water dwellers often mirror those of their
standard races, though water folk favor more loosely organized
governments and more open societies. Water creatures generally have
plenty of space available for building, so they rarely structure their
habitats as densely populated cities. Instead, undersea races prefer to
maintain loosely defined territories in which families and individuals
can carve out their own niches.
    <h6>Religion</h6>
Water creatures usually pay homage to at least one deity related to
oceans, storms, or nature. Otherwise, their worshiping habits are
similar to those of their standard races.
    <h6>Language</h6>
Water creatures speak any languages spoken by members of their standard
race. Many also learn Aquan to facilitate communication with other
underwater creatures.
    <h6>Adventurers</h6>
While there are plenty of adventures to be found beneath the waves,
water creatures occasionally venture onto land to fulfill some
important goal. Some are lured by the alien ways of the surface people,
others feel a wanderlust that can be tamed only by walking the earth.
    <h4><a id="aquatic-racial-traits"></a>General Aquatic Racial Traits</h4>
    <p>
All aquatic races have the following racial traits.
    </p>
    <ul>
      <li>Aquatic: An aquatic race gains the <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#aquatic">aquatic subtype</a>.
An aquatic creature can breathe underwater. It cannot also breathe air
unless it also has the amphibious special quality. An aquatic creature
can hold its breath outside the water for 2 rounds per point of
Constitution. After that, it begins to suffocate.</li>
      <li>Swim speed: An aquatic creature always has a swim speed. It can move through water at its swim speed without making <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#swim">Swim</a>
checks. It has a 8 racial bonus on any Swim check to perform some
action or avoid a hazard. It can always choose to take 10 on a Swim
check, even if distracted or endangered. It can use the run action
while swimming, provided it swims in a straight line.</li>
      <li>Bonus Language: Aquan. Aquatic races are familiar with the language of water-based creatures.</li>
    </ul>
    <h4><a id="aquatic-race-level-adjustment"></a>Aquatic Race Level Adjustments</h4>
None of the aquatic races have level adjustments when your entire campaign is set underwater and all the PCs have the <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#aquatic">aquatic subtype</a>,
or when playing a nonaquatic campaign. The advantages gained by an
aquatic character when in an aquatic environment even out with those of
other aquatic characters, and their disadvantages in nonaquatic
environments make up for any advantages they might enjoy.
    <p> However, when a mix of aquatic and nonaquatic characters
occurs in an aquatic or ship-based campaign, aquatic characters enjoy a
distinct advantage over their land-based cousins. In this case,
consider applying a +1 level adjustment for all aquatic races, due to
their swim speed, improved low-light vision or darkvision, and other
special abilities.
    </p>
    <h3><a id="aquatic-dwarves"></a>AQUATIC DWARVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Beneath the waves, dwarves remain the masters of stone. They build
massive, elaborate fortresses near the mineral-rich waters that
surround undersea thermal vents.
    <h5>Racial Traits</h5>
Aquatic dwarves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all the <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#dwarves">dwarf racial traits</a>, as well as those noted below.
    <ul>
      <li>+2 Strength, +2 Constitution, -4 Dexterity, -2 Charisma.
Aquatic dwarves are tough enough to survive the brutal environment of
the sea floor, but rely on strength and heavy armor rather than agility.</li>
      <li>An aquatic dwarf has a swim speed of 20 feet.</li>
    </ul>
    <h3><a id="aquatic-elves"></a>AQUATIC ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
The aquatic landscape is dotted with forests of kelp and roofed with
sporadic blankets of sargasso weed. Hidden among these watery groves
are the ancient settlements of the elves.
    <h5>Racial Traits</h5>
Aquatic elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#elves">elf racial traits</a>, and <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersEtoF.html#aquatic-elf">aquatic elf traits</a>, with the following exception.
    <ul>
      <li>No gills.</li>
    </ul>
    <h3><a id="aquatic-gnomes"></a>AQUATIC GNOMES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic gnomes travel the world beneath the waves in elaborate wood and
shell chariots pulled by domesticated sea creatures. Their vehicles
range in size from single-person launches to those large enough to
house several families.
    <h5>Racial Traits</h5>
Aquatic gnomes have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#gnomes">gnome racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>An aquatic gnome has a swim speed of 20 feet.</li>
      <li>Well-Traveled: Aquatic gnomes receive a +2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">Sense Motive</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#gather-information">Gather Information</a> checks. They always know when their welcome is wearing out.</li>
      <li>No +1 racial bonus on attack rolls against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersKtoL.html#kobold">kobolds</a>:
Aquatic gnomes do not come into combat with kobolds as frequently as
their land-bound cousins. They retain their bonus against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#goblinoid">goblinoids</a>, however. </li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (alchemy) checks or <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">Listen</a> checks: Aquatic gnomes' visual acuity has improved at the expense of their other senses.</li>
    </ul>
    <h3><a id="aquatic-goblins"></a>AQUATIC GOBLINS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic goblins are feared and reviled as ocean-dwelling piranhas. A band of aquatic goblins and their <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersAnimal.html#shark">shark</a>
mounts is likely to swoop down on any individual or group perceived as
weak, to strip it of everything from gold and jewelry to clothing and
hides. Leaving a trail of junk behind them as they evaluate their loot,
the bandits swim off in search of other unlucky victims.
    <p> Aquatic goblins to take up adventuring for many reasons.
While most goblins are neutral evil, many have different beliefs and
seek like-minded individuals outside the tribe. Some lack opportunities
to advance, and tire of living on the scraps of those with more
authority or higher social position.
    </p>
    <h5>Racial Traits</h5>
Aquatic goblins have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersG.html#goblin">goblin racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-2 Strength, +2 Constitution, -2 Charisma. Aquatic goblins are hardy creatures, but weaker than many races.</li>
      <li>An aquatic goblin has a swim speed of 30 feet.</li>
      <li>Thievery: Aquatic goblins get a +2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#disable-device">Disable Device</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sleight-of-hand">Sleight of Hand</a> checks.</li>
      <li>An aquatic goblin's racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#move-silently">Move Silently</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#ride">Ride</a> checks is only +2. Aquatic goblins are known for riding Medium <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersAnimal.html#shark">sharks</a>.</li>
    </ul>
    <h3><a id="aquatic-half-elves"></a>AQUATIC HALF-ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-elves">
Aquatic elves</a> sometimes wed <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-humans">aquatic humans</a>,
just as land-based elves sometimes marry surface dwelling humans. The
aquatic results are aquatic half-elf children. They often serve as the
diplomats and negotiators for either their human or elf communities,
depending on where they live.
    <h5>Racial Traits</h5>
Aquatic half-elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-elves">half-elf racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>An aquatic half-elf has a swim speed of 40 feet.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#gather-information">Gather Information</a> checks.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a>
checks: Aquatic half-elves spend a great deal of time traveling between
elf and human settlements on their diplomacy work, and so are
accustomed to surviving in the undersea wilderness.</li>
    </ul>
    <h3><a id="aquatic-half-orcs"></a>AQUATIC HALF-ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In their remote and wild tribal homes, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-orcs">aquatic orcs</a> occasionally coexist with barbarian tribes of <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-humans">aquatic humans</a>. Sometimes the two groups intermarry, producing aquatic half-orcs.
    <h5>Racial Traits</h5>
Aquatic half orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-orcs">half-orc racial traits</a>, in addition to those noted below.
    <ul>
      <li>An aquatic half-orc has a swim speed of 30 feet.</li>
      <li>Emissaries: As emissaries for their tribes, aquatic half-orcs receive a +2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#diplomacy">Diplomacy</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#gather-information">Gather Information</a> checks. They aren't the best diplomats under the waves, but they're a far sight better than their orc cousins.</li>
    </ul>
    <h3><a id="aquatic-halflings"></a>AQUATIC HALFLINGS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic halflings are much more settled than their surface-dwelling cousins.
Their slight size means they are easily buffeted by currents that flow beneath
the waves like the rivers of the surface world. Many predatory creatures think
nothing of swooping down on such small prey. As a result, aquatic halflings often
live among <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-dwarves">dwarves</a> and <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-humans">humans</a>, within the defenses those races provide.
    <h5>Racial Traits</h5>
Aquatic halflings have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#halflings">halfling racial traits</a>, in addition to those noted below.
    <ul>
      <li>An aquatic halfling has a swim speed of 20 feet.</li>
      <li>Low-Light Vision: An aquatic halfling can see four times as far as a
nonaquatic human in starlight, moonlight, torchlight, and similar conditions
of poor illumination. He retains the ability to distinguish color and detail
under these conditions.</li>
    </ul>
    <h3><a id="aquatic-humans"></a>AQUATIC HUMANS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic humans have a place beneath the surface of the sea, perhaps as descendants
of forgotten civilizations whose island cities sank beneath the surface. They may
replace <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersMtoN.html#merfolk">merfolk</a> in such campaigns, or exist along with them.
    <h5>Racial Traits</h5>
Aquatic humans have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#humans">human racial traits</a>, in addition to those noted below.
    <ul>
      <li>An aquatic human has a swim speed of 30 feet.</li>
      <li>Low-Light Vision: An aquatic human can see four times as far as a nonaquatic
human in starlight, moonlight, torchlight, and similar conditions of poor illumination.
He retains the ability to distinguish color and detail under these conditions.</li>
    </ul>
    <h3><a id="aquatic-kobolds"></a>AQUATIC KOBOLDS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic kobolds are seldom seen outside their mines, much like their surface-dwelling
cousins &mdash; despite the fact that their broad tails make them exceptional swimmers.
They dig constantly, always expanding their subterranean territory, and as a
result frequently come into conflict with <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-dwarves">aquatic dwarves</a>. Aquatic kobolds often
hire mercenaries to fight for them, hiding behind their carefully constructed
traps and other defenses and paying their defenders with the mineral wealth
they uncover while expanding their warrens.
    <p>
The life of an aquatic kobold is one of constant toil and fear. Some few kobolds
seek peace of mind through personal power and boon companions. These rare
individuals become adventurers.
    </p>
    <h5>Racial Traits</h5>
Aquatic kobolds have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersKtoL.html#kobold">kobold racial traits</a>, as well as the following trait.
    <ul>
      <li>An aquatic kobold has a swim speed of 40 feet.</li>
    </ul>
    <h3><a id="aquatic-orcs"></a>AQUATIC ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Aquatic orcs survive on the slopes of cliff sides or undersea drops, far from
the warmth of volcanic vents and the nurturing light of the sun. As such, they
control access to the secrets of the deep ocean trenches &mdash; but are menaced by fell
creatures that rise up out of the trenches in search of fresh prey.
    <p>
Aquatic orcs stand ever vigilant against attackers and thieves when they're not
making preemptive strikes against those foolish enough to settle near their territory.
    </p>
    <p>
Occasionally an individual orc questions the need for constant battle, or seeks
strategies other than brute force. Such iconoclasts often leave their tribes &mdash; at
least temporarily &mdash; to become adventurers.
    </p>
    <h5>Racial Traits</h5>
Aquatic orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#aquatic-racial-traits">aquatic racial traits</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersOtoR.html#orc">orc
racial traits</a>, as well as the following trait.
    <ul>
      <li>An aquatic orc has a swim speed of 30 feet.</li>
    </ul>
    <h2><a id="arctic-races"></a>Arctic Races</h2>
Your campaign might be set in a snow-swept expanse of glaciers, mountains, and
icy seas. Settlements cluster around sources of heat, including hot springs and
volcanic vents. Frozen in the ice are relics of a forgotten, warmer time. Strange
creatures descend from the glaciers, wreaking havoc and drawing adventurers like
beacons.
    <p>
Players wanting their characters to be nonhuman arctic dwellers can build characters
using these variants, or their characters could encounter these races while journeying
across the ice and snow.
    </p>
    <p>
The following cultural attributes are common to most arctic races.
    </p>
    <h6>Personality</h6>
Nearly all ice dwellers are more grim and humorless than members of their standard
races. Efficiency in word and deed is crucial to survival in the harsh arctic
realms, so arctic creatures tend to get straight to the point in conversation,
and they have little patience for those who speak in riddles or half truths. Because
they must struggle for even the most basic existence in the severe climate of
the arctic regions, excess of any kind is offensive to them. Thus, they tend to
be frugal and to value hard work for the benefit of all over individual accomplishment.
    <h6>Physical Description</h6>
An ice dweller is hardier than a member of his standard race, and he tends to
carry extra weight on his frame even when in exceptional physical condition. His
skin can be any shade from pale white to light blue, and his hair and eyes can
be of any color found among members of his standard race.
    <p>
Arctic creatures wear many layers of clothing as protection against the intense
cold of their homeland. They do not particularly value jewelry or other ornamentation,
and they carry only what they need to survive,
    </p>
    <h6>Relations</h6>
Those who spend their lives in the bleak and comfortless arctic regions treat
outsiders with guarded wariness, if not outright distrust. Though members of most
arctic tribes are willing to welcome strangers in from the cold, they tend to
watch such newcomers very closely and hurry them on their way as soon as possible.
Of course, a stranger with a particularly useful skill may be invited to stay &mdash; it
is difficult to turn away a strong back or a skilled toolmaker in the desolate
and frigid lands where the arctic races make their homes.
    <p>
In addition to their particular distrust of outsiders, members of arctic races
tend to harbor many of the same prejudices held by their standard races.
    </p>
    <h6>Alignment</h6>
Arctic races are generally inclined toward the alignment preferences of their
standard races.
    <h6>Lands</h6>
It is difficult for any living thing to survive in the frozen wastelands where
arctic races live. Thus, most other races know little or nothing of their arctic
cousins, and what they do know is often tainted by legend and hearsay. Most arctic
creatures make their homes in ice caves or beneath the ground, although some tribes
have adapted to life on the surface by building ice houses and other structures
that can survive fierce winter storms.
    <h6>Religion</h6>
Arctic races worship deities typical of their standard races.
    <h6>Adventurers</h6>
Ice dwellers must exert themselves merely to survive in the bitter climate of
their homelands, so they tend to make ideal adventurers. Those who dislike the
constant struggle of life in the arctic often travel far from their homes in search
of comfort, if not adventure. Occasionally, arctic tribes send emissaries into
temperate lands to gather aid against particularly strong enemies or to secure
emergency stores during hard times.
    <h4><a id="arctic-racial-trait"></a>General Arctic Racial Trait</h4>
All arctic races have the following racial trait.
    <ul>
      <li>Cold Endurance: Members of arctic races have a +4 racial bonus on Fortitude
saves made to resist the effects of <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="wildernessAndEnvironment.html#cold-dangers">cold weather or exposure</a>.</li>
    </ul>
    <h3><a id="arctic-dwarves"></a>ARCTIC DWARVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
In the icy wastes, dwarves are the masters of both ice and stone. They build massive,
elaborate defenses of ice and rock around the mines where they dig for copper,
silver, gold, and iron.
    <h5>Racial Traits</h5>
Arctic dwarves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#dwarves">dwarf
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 Strength, +2 Constitution, -4 Dexterity, -2 Charisma. Arctic dwarves
are tough enough to survive the brutal environment of the arctic, but rely on
strength and heavy armor rather than agility.</li>
      <li>Icecunning: Arctic dwarves can apply their stonecunning benefits to structures
and natural features made of ice, as well as those made of stone.</li>
      <li>+1 racial bonus on attack rolls against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersKtoL.html#kobold">kobolds</a>: Arctic dwarves must defend
against constant assaults by kobolds. This trait replaces the dwarfs racial bonus
on attack rolls against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersOtoR.html#orc">orcs</a>.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#appraise">Appraise</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> checks related to items made of ice.</li>
    </ul>
    <h3><a id="arctic-elves"></a>ARCTIC ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
The arctic landscape is dotted here and there with permanent fog banks caused
by subterranean heat interacting with snow and ice or warm springs steaming in
the freezing air. Hidden in these oases of warmth are the ancient settlements
of the elves.
    <h5>Racial Traits</h5>
Arctic elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#elves">elf
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-2 Strength, +2 Dexterity: Arctic elves look down on physical exertion,
preferring quick action when necessary.</li>
      <li>+2 racial bonus on any one <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> skill (chosen by the character at the
time of creation).</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a> checks made in arctic environments: Arctic
elves are naturally adept at staying alive in the harsh conditions of their homeland.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#search">Search</a> checks.</li>
      <li>No special ability to notice a secret or concealed door within 5 feet without
actively looking for it.</li>
    </ul>
    <h3><a id="arctic-gnomes"></a>ARCTIC GNOMES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Arctic gnomes travel the ice and snow on cleverly constructed sleds pulled by
immense woolly mammoths. The animals serve as transportation, a source of fibers
for weaving, and walls against the elements. The sleds are enclosed, often transporting
entire families.
    <h5>Racial Traits</h5>
Arctic gnomes have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#gnomes">gnome
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">Sense Motive</a> checks: Arctic gnomes always seem to know
instinctively when their welcome is wearing out.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (alchemy) checks.</li>
    </ul>
    <h3><a id="arctic-goblins"></a>ARCTIC GOBLINS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Arctic goblins are feared and reviled because their appearance heralds theft,
assaults, and property damage. They survive by moving from place to place, constantly
driven out of their previous homes by irate neighbors.
    <p>
Arctic goblins to take up adventuring for many reasons. While most goblins are
neutral evil, many have different beliefs and seek like-minded individuals outside
the tribe. Some just want an opportunity to settle down and work to earn a better
reputation than that of their fellows.
    </p>
    <h5>Racial Traits</h5>
Arctic goblins have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersG.html#goblin">goblin
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-2 Strength, +2 Constitution, -2 Charisma. Arctic goblins are hardy creatures,
but weaker than many races.</li>
      <li>Low-Light Vision: An arctic goblin can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. She retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#bluff">Bluff</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sleight-of-hand">Sleight of Hand</a> checks: Arctic goblins are
natural thieves, snatching up anything that isn't nailed down.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#move-silently">Move Silently</a> checks: Arctic goblins aren't as stealthy
as their nonarctic cousins.</li>
    </ul>
    <h3><a id="arctic-half-elves"></a>ARCTIC HALF-ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-elves">
Arctic elves</a> wed humans as often as basic elves do, producing arctic half-elf
children. These half-breeds tend to have great wanderlust, and are uncomfortable
staying in one place for very long. They often carry messages and rumors with
them as they travel between communities.
    <h5>Racial Traits</h5>
Arctic half-elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-elves">half-elf racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#diplomacy">Diplomacy</a> checks: Their transient nature prevents arctic
half-elves from being as well trusted as their normal counterparts.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a> checks: Arctic half-elves spend a great deal
of time traveling between elf and human settlements, and are accustomed to surviving
in the wilderness.</li>
    </ul>
    <h3><a id="arctic-half-orcs"></a>ARCTIC HALF-ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Many tribes of <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-orcs">arctic orcs</a> eke out a living on the warm slopes of volcanic mountains,
herding elk and caribou. often their mutual dependence on sources of fresh water
and meat brings them into contact with arctic-dwelling human barbarians. The resulting
offspring are sometimes welcome in places where full orcs are not, valued for
their tenacity, courage, and combat prowess.
    <h5>Racial Traits</h5>
Arctic half-orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-orcs">half-orc racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: An arctic half-orc can see twice as far as a human in
starlight, moonlight, torchlight, and similar conditions of poor illumination.
He retains the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#diplomacy">Diplomacy</a> checks: Arctic half-orcs often serve as emissaries
for the arctic orc tribes that spawned them.</li>
    </ul>
    <h3><a id="arctic-halflings"></a>ARCTIC HALFLINGS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Arctic Halflings are hunters and fishermen, stalking walrus and seal and putting
to sea to fish, usually in the company of larger races such as humans. They are
a peaceful, spiritual people whose rituals include preexpedition prayers for forgiveness
from the animals they hope to kill.
    <h5>Racial Traits</h5>
Arctic Halflings have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#halflings">halfling racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#swim">Swim</a> checks: Arctic halflings rarely swim by choice,
but are reasonably good at saving themselves from drowning.</li>
      <li>No racial bonus on attack rolls with slings. Arctic Halflings do not particularly
favor slings for hunting.</li>
      <li>Favored Class: <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="ranger.html">Ranger</a>. A multiclass arctic halfling's ranger class does
not count when determining whether she takes an experience point penalty for multiclassing.
This trait replaces the base halfling's favored class.</li>
    </ul>
    <h3><a id="arctic-kobolds"></a>ARCTIC KOBOLDS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Arctic kobolds are seldom seen outside their mines. They dig constantly, always
expanding their subterranean territory (which is often carefully constructed to
tap into geothermal vents for heat, and as a result are in constant conflict with
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-dwarves">arctic dwarves</a>. Arctic kobolds often hire mercenaries to fight for them, hiding
behind their carefully constructed traps and other defenses and paying their defenders
with the mineral wealth they uncover while expanding their warrens.
    <h5>Racial Traits</h5>
Aquatic kobolds have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersKtoL.html#kobold">kobold
racial traits</a>, with one exception as noted below.
    <ul>
      <li>-2 Strength, +2 Dexterity, -2 Constitution, -2 Wisdom: Arctic kobolds are
stronger. than other kobolds, but are weaker of will.</li>
    </ul>
    <h3><a id="arctic-orcs"></a>ARCTIC ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Arctic orcs are primitive herders, eking out a living on the slopes of volcanic
mountains and building their crude huts near hot springs or steam vents. They
herd woolly cattle, caribou, and elk. As a result, they control large sources
of meat, water, and heat &mdash; all things coveted by others living in or traveling through
the arctic. What they don't have, they take by force.
    <p>
Arctic orcs stand ever vigilant against attackers and thieves when they're not
making preemptive strikes against those foolish enough to settle near their territory.
    </p>
    <p>
Occasionally an individual orc questions the need for constant battle, or seeks
strategies other than brute force. Such iconoclasts often leave their tribes &mdash; at
least temporarily &mdash; to become adventurers.
    </p>
    <h5>Racial Traits</h5>
Arctic orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#arctic-racial-trait">arctic racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersOtoR.html#orc">orc racial
traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: An arctic orc can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. He retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>No light sensitivity.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#handle-animal">Handle Animal</a> checks: Arctic orcs are herders, and they
rely on their skills with animals to keep their tribes fed.</li>
    </ul>
    <h2><a id="desert-races"></a>Desert Races</h2>
In a campaign set in a vast desert, water is worth its weight in gold, and shifting
sands occasionally reveal long-buried ruins of forgotten civilizations.
    <p>
Players wanting their characters to be nonhuman desert dwellers can build characters
using these variants, or their characters could encounter these races while journeying
across the sands.
    </p>
    <p>
The following cultural attributes are common to most desert races.
    </p>
    <h6>Personality</h6>
Members of the desert races are usually quite friendly, although during the day
they may seem brusque as they hurry to get about their business and out of the
oppressive heat. At night, however, they offer warm greetings and good drink to
any travelers who happen upon their camps. To them, a stranger is neither friend
nor foe &mdash; just good company whose presence brings a moment of joy.
    <h6>Physical Description</h6>
A desert creature is usually hardier in build than a member of his standard race.
Bronze skin and dark hair are typical, since such adaptations tend to make life
in the scorching desert more comfortable.
    <p> The clothing favored by desert creatures tends to be loose and light, and they
wear just enough to keep the heat off during the day and the chill off at night.
Most desert creatures wear headgear that not only protects them from the sun but
also provides some warmth during cold desert nights.
    </p>
    <h6>Relations</h6>
Most desert dwellers are not choosy about trading partners, but many share the
prejudices of their standard races. Regardless of race, most share a dislike of
the scavenging <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-kobolds">desert kobolds</a> and the omnipresent nuisance of <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-goblins">desert goblins</a>.
    <h6>Alignment</h6>
The predominant alignment among desert races is neutral. It takes equal parts
of law and chaos to survive under the harsh desert sun. Evil folk soon find themselves
without friends, and to be isolated in an environment that cannot long support
individuals means certain death. In contrast, those who place the principles of
goodness over the needs of survival often find themselves in dire straits. Life
in the desert often requires one to meet one's own needs before tending to another's.
    <h6>Lands</h6>
Except for <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-dwarves">desert dwarves</a> and <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-gnomes">desert gnomes</a>, most desert dwellers are nomads who
wander from one settlement to the next in search of vital supplies. Thus, they
have no true lands of their own. Desert dwarves, however, are very protective
of their settlements and private oases, and they are willing to defend them to
the death if need be.
    <h6>Religion</h6>
Desert creatures tend to have the same religious preferences as members of their
standard races, with an emphasis on the worship of sun, light, or fire deities.
    <h6>Language</h6>
Members of desert races speak the same languages as their standard races.
    <h6>Adventurers</h6>
Opportunity for adventure is common on the burning sands. Denizens of the desert
may turn to adventuring because they find the scavenger's life too difficult, or
simply because they crave the rewards of the adventuring life. Although nomadic
societies are often made up of networks of extended families, those who leave
them to pursue their own goals rarely return.
    <h4><a id="desert-racial-trait"></a>General Desert Racial Trait</h4>
All desert races gain the following racial trait.
    <ul>
      <li>Heat Endurance: Members of desert races gain a +4 racial bonus on Fortitude
saves made to resist the effects of <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="wildernessAndEnvironment.html#heat-dangers">hot weather</a>.</li>
    </ul>
    <h3><a id="desert-dwarves"></a>DESERT DWARVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Because of their skill with stone and engineering, dwarves are acknowledged masters
of locating water and digging wells. The importance of water to desert peoples
gives the dwarves both great wealth and great political power.
    <h6>Racial Traits</h6>
Desert dwarves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#dwarves">dwarf
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-2 Dexterity, +2 Constitution: Desert dwarves are tough enough to survive
the brutal environment of the desert, but they rely more on cunning and fast talk
than agility.</li>
      <li>No stonecunning.</li>
      <li>+1 racial bonus on attack rolls against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#humanoid">humanoids</a> of the <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#reptilian">reptilian subtype</a>
(including <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersKtoL.html#kobold">kobolds</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersKtoL.html#lizardfolk">lizardfolk</a>) and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#dragon">dragons</a> (including <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersHtoI.html#half-dragon">half-dragons</a>): Desert
dwarves are trained in special combat techniques that allow them to fight common
enemies more effectively. This bonus replaces the normal dwarfs bonus against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersOtoR.html#orc">orcs</a>
and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#goblinoid">goblinoids</a>.</li>
      <li>+4 dodge bonus to Armor Class against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#dragon">dragons</a>: This replaces the dwarf's
dodge bonus to AC against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#giant">giants</a>. Dragons are a more common threat than giants
in desert climes, and desert dwarves have adapted to meet this challenge.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#knowledge">Knowledge</a> (architecture and engineering), Knowledge
(dungeoneering), and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#profession">Profession</a> (miner) checks: Desert dwarves protect a great
deal of information about what lives beneath the desert and how to reach it.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> checks.</li>
    </ul>
    <h3><a id="desert-elves"></a>DESERT ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79204.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
In the desert, elves follow nomadic lifestyles. They herd horses, cattle, and
goats across the sands, sleeping during the day and working or traveling at night.
Their wandering takes them many places and puts them in contact with many cultures.
As a result, they are welcome everywhere for the news and exotic trade goods they carry.
    <h5>Racial Traits</h5>
Desert elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#elves">elf
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-2 Strength, +2 Dexterity: Desert elves are physically weaker than their
normal cousins, but are also hardier, toughened by the harsh reality of desert
life.</li>
      <li>Weapon Proficiency: Desert elves receive the Martial Weapon Proficiency
feats for the scimitar, rapier, and shortbow (including composite shortbow) as
bonus feats. Desert elves prefer the scimitar to the longsword and the shortbow
to the longbow, because they can use shortbows while mounted.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#handle-animal">Handle Animal</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#ride">Ride</a> checks: Desert elves spend most
of their lives riding and working with animals.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">Listen</a> checks.</li>
    </ul>
    <h3><a id="desert-gnomes"></a>DESERT GNOMES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Desert dwarves may dig and maintain wells, but desert gnomes pay for them. Desert
gnomes establish and operate fortified trading posts around wells and oases, cultivating
crops wherever possible and scouring the surrounding desert for the wealth of
buried civilizations. Desert gnome outposts are rarely quiet, doing business both
day and night.
    <h5>Racial Traits</h5>
Desert gnomes have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#gnomes">gnome
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#bluff">Bluff</a>, <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#diplomacy">Diplomacy</a>, and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">Sense Motive</a> checks: Desert gnomes
are skilled at haggling and negotiating.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">Listen</a> checks or <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (alchemy) checks.</li>
    </ul>
    <h3><a id="desert-goblins"></a>DESERT GOBLINS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Desert goblins are feared and reviled because their appearance often heralds the
arrival of a horde of nomadic <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersHtoI.html#hobgoblin">hobgoblins</a> and <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersBtoC.html#bugbear">bugbears</a>. As the desert dwarf proverb
puts it, &ldquo;The only good thing about goblins is that once they arrive, you know
they'll soon be gone.&rdquo;
    <p>
Desert goblins take up adventuring for many reasons. While most goblins are neutral
evil, many have different beliefs and seek like-minded individuals outside the
tribe. Some lack opportunities to advance, and they tire of living on the scraps
of those with more authority or higher social position.
    </p>
    <h5>Racial Traits</h5>
Desert goblins have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersG.html#goblin">goblin
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: A desert goblin can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. He retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#gather-information">Gather information</a> checks: Desert goblins soak up gossip
like sponges when they visit towns or settlements.</li>
    </ul>
    <h3><a id="desert-half-elves"></a>DESERT HALF-ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-elves">
Desert elves</a> often wed humans, just as their more common cousins do, producing
desert half-elf children. These offspring often serve as diplomats and negotiators
for either their human or elf communities, depending on where they live.
    <h5>Racial Traits</h5>
Desert half-elves have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-elves">half-elf racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">Sense Motive</a> checks: A desert half-elfs survival often
relies on his ability to gauge the social situation.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">Listen</a> checks.</li>
    </ul>
    <h3><a id="desert-half-orcs"></a>DESERT HALF-ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Many tribes of <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-orcs">orcs</a> live in isolated desert canyons or the ruins of ancient cities,
now abandoned to the sands. Often their mutual dependence on sources of fresh
water brings them into contact with desert-dwelling human barbarians. The resulting
offspring valued for their tenacity, courage, and combat prowess, are often welcome
in places where full-blooded orcs are not.
    <h5>Racial Traits</h5>
Desert half-orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-orcs">half-orc racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 Constitution, -2 Intelligence: The desert half-orc is lean and hardy
rather than muscular, but has adapted to be more socially adept than a typical
half-orc.</li>
      <li>Low-Light Vision: A desert half-orc can see twice as far as a human in
starlight, moonlight, torchlight, and similar conditions of poor illumination.
He retains the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>Run: Desert half-orcs receive <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="featsAll.html#run">Run</a> as a racial bonus feat. Typically too
poor to afford mounts, half-orcs learn from an early age to get from place to
place by running.</li>
    </ul>
    <h3><a id="desert-halflings"></a>DESERT HALFLINGS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Like gnomes, halflings are most likely to be active during the day. Most other
races regard them somewhat like cats, in that they appear whether invited or not,
work at what they want when they want, and disappear without prior notice. A desert
gnome proverb observes that &ldquo;Halflings in your town are a source of trouble, but
at least they don't tolerate competition.&rdquo;
    <h5>Racial Traits</h5>
Desert halflings have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#halflings">halfling racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#hide">Hide</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sleight-of-hand">Sleight of Hand</a> checks: Desert halflings are
stealthy and nimble-fingered.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">Climb</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#jump">Jump</a> checks.</li>
    </ul>
    <h3><a id="desert-kobolds"></a>DESERT KOBOLDS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Desert kobolds live like scaly, intelligent rats, hiding in forgotten corners
of settlements, living just over the dunes from trading posts, and always reappearing
right after the residents think they've finally been killed off.
    <p>
Desert kobolds claim they were among the first to settle the deserts, and that
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#desert-gnomes">gnomes</a> threw them out into the wilderness. Though their survival relies on goods
available at oasis settlements and trading posts, desert kobolds hate the gnomes
who often build and run such settlements.
    </p>
    <p>
The life of a desert kobold is one of constant fear, which motivates them to build
traps and fortifications and to ambush intruders. Some few kobolds, however, seek
peace of mind through personal power and boon companions. These rare individuals
become adventurers.
    </p>
    <h5>Racial Traits</h5>
Desert kobolds have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersKtoL.html#kobold">kobold
racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-4 Strength, +2 Dexterity, -2 Wisdom: Desert kobolds are hardier than other
kobolds, but are weak-willed.</li>
      <li>No light sensitivity.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a> checks.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#profession">Profession</a> (mining) checks: Desert kobolds don't mine.</li>
      <li>Favored Class: <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="rogue.html">Rogue</a>. A multiclass desert kobold's rogue class does not
count when determining whether he takes an experience point penalty for multiclassing.
This trait replaces the normal kobold's favored class.</li>
    </ul>
    <h3><a id="desert-orcs"></a>DESERT ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Desert orcs are martial creatures who live for battle. Prowess in battle is their
only measure of social standing, and they don't particularly care if weaker races
get hurt when orcs test their skills. In fact, the chief difference between desert
orcs and desert goblinoids is that the former never accept defeat &mdash; when desert orcs
attack, they conquer or die trying.
    <p>
Desert orcs live in a perpetual state of war, participating in eternal night sorties
from their isolated desert canyons and ancient ruined cities to attack settlements
and expand their territory. Only the burning sun, and their weakness when in it,
keeps them from ruling the desert.
    </p>
    <p>
Occasionally an individual orc questions the need for constant battle, or seeks
strategies other than brute force. Such iconoclasts often leave their tribes &mdash; at
least temporarily &mdash; to become adventurers.
    </p>
    <h5>Racial Traits</h5>
Desert orcs have the general <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#desert-racial-trait">desert racial trait</a> described above and all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersOtoR.html#orc">orc racial
traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: A desert orc can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. She retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>Endurance: Desert orcs gain <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="featsAll.html#endurance">Endurance</a> as a racial bonus feat.</li>
    </ul>
    <h2><a id="jungle-races"></a>Jungle Races</h2>
A vast equatorial jungle, where tiny trading posts hacked from the vegetation
dot riversides, giant vines and tree roots conceal cities of forgotten societies,
and races that predate human civilization struggle to maintain their ancient traditions,
makes a compelling setting for a campaign.
    <p>
Players wanting their characters to be nonhuman jungle dwellers can build characters
using these variants, or their characters could encounter these races while journeying
through the green labyrinth of the jungle.
    </p>
    <p>
The following cultural attributes are common to most jungle dwelling races.
    </p>
    <h6>Personality</h6>
Jungle races tend to be very territorial, and they guard their families and clans
closely. They are not quick to trust strangers, but they form strong bonds with
outsiders who do manage to earn their trust.
    <h6>Physical Description</h6>
A jungle dweller looks very much like a member of her standard race. Typically,
only a jungle creature's rough, hide-sewn clothing and unkempt appearance mark
her as unusual.
    <h6>Relations</h6>
Members of the jungle races are very territorial, so they interact with other
creatures only when absolutely necessary. They often maintain good trading relations
with other wild races, but they rarely trade with civilized cultures except through
intermediaries such as local druids or rangers.
    <h6>Alignment</h6>
Jungle races are often more chaotic than members of their standard races, rejecting
the typical strictures of civilized society. However, their loyalty to family
is high.
    <h6>Lands</h6>
Jungle races avoid heavily populated areas, settling in remote areas of wilderness.
    <h6>Religion</h6>
Members of the jungle races tend to be more superstitious than religious. Most
rely on religious leaders (some of whom are actually <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="npcClasses.html#adept">adepts</a> of great power) to
give them spiritual guidance. Individuals may pray to aspects of animal and nature
deities, but only rarely does one of them achieve true communion with such a deity.
    <h6>Adventurers</h6>
Jungle dwellers may set out into the world for a variety of reasons. They may
need the help of a group of civilized folk to avert a prophesied disaster, or
they might be asked by tribal elders to retrieve an artifact from an ancient burial
site to protect them from a coming conflict. Often, such a quest is only the beginning
of a jungle creature's adventuring career. She may be lured away from her people
by the wonders of civilized society, or she may form a bond with outsiders who
will never be welcome in her tribal lands.
    <h3><a id="jungle-dwarves"></a>JUNGLE DWARVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Because of their height, dwarves can duck under vegetation that impedes humans &mdash; yet
they are stockier than gnomes or halflings, and able to bull through what they
can't avoid. Hardy to a fault, jungle dwarves are explorers, pathfinders, and
traveling merchants.
    <h5>Racial Traits</h5>
Jungle dwarves have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#dwarves">dwarf racial traits</a>, with additions and exceptions as
noted below.
    <ul>
      <li>Low-Light Vision: A jungle dwarf can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. She retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#heal">Heal</a>, <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#knowledge">Knowledge</a> (nature), and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a> checks: Jungle dwarves
know a great deal about what lives in the jungle, and are adept at finding it
(or avoiding it, as the case may be).</li>
      <li>+2 bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#spot">Spot</a> checks: Jungle dwarves have keen eyesight.</li>
      <li>No stonecunning: Jungle dwarves live aboveground.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> checks related to stone or metal items.</li>
      <li>Favored Class: <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="ranger.html">Ranger</a>. A multiclass jungle dwarfs ranger class does not
count when determining whether she takes an XP penalty for multiclassing. Jungle
dwarves need both outdoor skills and combat proficiency to survive. This trait
replaces the normal dwarfs favored class.</li>
    </ul>
    <h3><a id="jungle-elves"></a>JUNGLE ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Jungle elves are somewhat more primal than their baseline counterparts. In many
jungles, ancient elven cities have been swallowed up by the voracious encroachment
of trees, vines, and animals, leaving just a shadowy remnant of the population
to remember the former glories of their lost civilization.
    <h5>Racial Traits</h5>
Jungle elves have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#elves">elf racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Weapon Proficiency: Jungle elves receive the Martial Weapon Proficiency feats
for the handaxe, rapier, shortsword, and shortbow (including composite shortbow)
as bonus feats. Proficiency with a rapier is a vestige of past glories and an
honored tradition among jungle elves. The other weapons are useful tools for survival
in their environment.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#knowledge">Knowledge</a> (history) checks: Jungle elves pride themselves
on being the keepers of much lore that has been forgotten to others.</li>
      <li>No ability to notice secret or concealed doors by passing within 5 feet.</li>
    </ul>
    <h3><a id="jungle-gnomes"></a>JUNGLE GNOMES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Jungle gnomes live in and along the mighty rivers that wind through the jungles.
A gnome jungle town is often a collection of boats tied together across a river,
with channels to allow passing vessels through (after they pay a toll, of course).
Jungle gnomes are also seen traveling the rivers, trading what they can find to
those they meet along the way.
    <h5>Racial Traits</h5>
Jungle gnomes have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#gnomes">gnome racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>No racial bonus on attack rolls against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monstersKtoL.html#kobold">kobolds</a>.</li>
      <li>No dodge bonus to Armor Class against <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="monsterTypes.html#giant">giants</a>.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">Climb</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#swim">Swim</a> checks: Jungle gnomes live their lives
clambering in and out of boats, and in and out of the water.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (shipbuilding) checks: A little lumber and a few
simple tools make for a happy and industrious jungle gnome.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#use-rope">Use Rope</a> checks: Jungle gnomes consider rope the most useful
tool on their boats.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#listen">Listen</a> or <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (alchemy) checks.</li>
    </ul>
    <h3><a id="jungle-goblins"></a>JUNGLE GOBLINS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
If monkeys were evil and could speak, they'd be a lot like jungle goblins. Jungle
goblins are territorial, tree-dwelling savages with a sadistic streak a mile wide.
Their skills at climbing and moving from tree to tree, combined with their cunning
and vicious nature, cause even other <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monsterTypes.html#goblinoid">goblinoids</a> to respect them.
    <p>
Jungle goblins generally take up adventuring to gain personal power and serve
their ambitions within their clans, or because they somehow lack the evil nature
of the rest of their kind.
    </p>
    <h5>Racial Traits</h5>
Jungle goblins have <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersG.html#goblin">goblin racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>A jungle goblin has a climb speed of 20 feet. Jungle goblins are natural
climbers, able to scramble up trees with ease. A jungle goblin has a +8 racial
bonus on all <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">Climb</a> checks. It must make a Climb check to climb any wall or slope
with a DC of more than 0, but it always can choose to take 10, even if rushed or
threatened while climbing. If it chooses an accelerated climb, it moves at double
its climb speed and makes a single Climb check at a -5 penalty. It cannot run while
climbing. It retains its Dexterity bonus to Armor Class (if any) while climbing,
and opponents get no special bonus on their attacks against a climbing jungle goblin.
Also, jungle goblins add their Dexterity modifier to Climb checks instead of their
Strength modifier.</li>
      <li>Low-Light Vision: A jungle goblin can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. He retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+4 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#jump">Jump</a> checks: Jungle goblins are accomplished leapers.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#hide">Hide</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#move-silently">Move Silently</a> checks.</li>
      <li>Favored Class: <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="barbarian.html">Barbarian</a>. A multiclass jungle goblin's barbarian class
does not count when determining whether he takes an experience point penalty for
multiclassing. This trait replaces the normal goblins favored class.</li>
    </ul>
    <h3><a id="jungle-half-elves"></a>JUNGLE HALF-ELVES</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Some <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#jungle-elves">elf</a> communities believe that introducing human blood into their families
is a way to invigorate their race once again. Others prefer a mixed-race emissary
to deal with outsiders, rather than a pure-blood elf. Either way, jungle half-elves
are the result.
    <h5>Racial Traits</h5>
Jungle half-elves have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-elves">half-elf racial traits</a>, with additions and exceptions
as noted below.
    <ul>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#bluff">Bluff</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#sense-motive">Sense Motive</a> checks: Jungle half-elves must
maintain constant awareness of their place within society.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#diplomacy">Diplomacy</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#gather-information">Gather Information</a> checks.</li>
    </ul>
    <h3><a id="jungle-half-orcs"></a>JUNGLE HALF-ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
The jungle is a wonderful home for <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#jungle-orcs">orcs</a>. It provides them with places to hide,
plenty of food, and as much or as little contact with other races as they want &mdash; which
is always more than the other races want. On occasion, such contact with humans
produces half-orcs.
    <h5>Racial Traits</h5>
Jungle half-orcs have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#half-orcs">half-orc racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: A jungle half-orc can see twice as far as a human in
starlight, moonlight, torchlight, and similar conditions of poor illumination.
He retains the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">Climb</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#jump">Jump</a> checks: Jungle half-orcs move almost
as easily through the trees of their jungle home as they do on the ground.</li>
    </ul>
    <h3><a id="jungle-halflings"></a>JUNGLE HALFLINGS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Jungle Halflings live a much more settled life than their normal counterparts
do. They build villages along rivers and burn clearings in the jungle to plant
their crops. In addition, they gather the most abundant food source in their environment,
fish, and hunt with poison-tipped weapons.
    <h5>Racial Traits</h5>
Jungle Halflings have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="races.html#halflings">halfling racial traits</a>, with additions and exceptions
as noted below.
    <ul>
      <li>Weapon Proficiency: Jungle Halflings receive the Martial Weapon Proficiency
feats for the throwing axe, handaxe, and shortbow (including composite shortbow)
as bonus feats. For jungle halflings, the axe is both a practical tool and a ceremonial
item. They grow up using the other weapons for hunting and fishing.</li>
      <li><a style={{
          "color": "rgb(87, 158, 182)"
        }} href="abilitiesAndConditions.html#poison">Poison</a> Use: jungle halflings use poison extensively when hunting, and as
a result never poison themselves when applying poison to a weapon or when using
a poisoned weapon in combat. Jungle halflings make extensive use of poisons harvested
from vermin, particularly those that damage Dexterity scores.</li>
      <li>No racial bonus on saving throws. Jungle Halflings have no unusual ability
to avoid mishaps.</li>
      <li>+2 racial bonus on Fortitude saying throws against poison: Jungle Halflings
have adapted a resistance to their own favorite method of bringing down prey.</li>
      <li>No morale bonus on saving throws against fear: Jungle halflings may be
cunning, but they are no braver than other races.</li>
      <li>No racial bonus on attack rolls with slings.</li>
      <li>Favored Class: <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="barbarian.html">Barbarian</a>. A multiclass halfling's barbarian class does
not count when determining whether she takes an experience point penalty for multiclassing.
Jungle halflings live much closer to nature, and must rely on cunning, resourcefulness,
persistence, courage, and mercilessness to survive. This trait replaces the normal
halfling's favored class.</li>
    </ul>
    <h3><a id="jungle-kobolds"></a>JUNGLE KOBOLDS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Clouded mountain peaks, many of which have religious or ceremonial importance
to those who live below, tower over jungle canopies. Few know of the ancient ruined
cities near the rocky summits, concealed amid the clouds. Dwelling in such ancient
ruins are the jungle kobolds.
    <p>
Those few explorers who have reached the cities report that their jungle kobold
residents claim to have built them, pointing to reptilian and draconic images
on the walls and in the architecture as evidence. The explorers report, however,
that the cities were obviously built by and for people larger than the little
reptilian humanoids. Jungle kobolds lack the cowardice common to other kobolds,
thanks to the isolation of their mountain peak homes. By all reports they show
a calmer, more generous side than other kobolds do.
    </p>
    <h5>Racial Traits</h5>
Jungle kobolds have <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersKtoL.html#kobold">kobold racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>-4 Strength, +2 Dexterity, -2 Intelligence: Life at high altitude has toughened
jungle kobolds, but their isolation has hindered their intellectual growth.</li>
      <li>Altitude Adaptation: Jungle kobolds are adapted to life at high altitudes,
and thus don't suffer <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="wildernessAndEnvironment.html#altitude-sickness">altitude sickness</a>. They are always considered acclimated
to high altitudes.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#climb">Climb</a> checks: Jungle kobolds add their Dexterity modifier
to Climb checks instead of their Strength modifier.</li>
      <li>No racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#craft">Craft</a> (trapmaking) or <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#profession">Profession</a> (miner) checks.</li>
    </ul>
    <h3><a id="jungle-orcs"></a>JUNGLE ORCS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
If gorillas were evil and could speak, they'd be a lot like jungle orcs. Jungle
orcs live a simple existence, moving through the jungle hunting and gathering
food in clan groups (equivalent in size to the squads given in the orc monster
entry, plus 150% noncombatants). Although combat is common within the clans, it's
relatively bloodless and hardly ever lethal. Only when jungle orcs encounter other
clans or other races do they show the true extent of their violent, evil nature.
    <p>
Conquest is a baffling concept to jungle orcs. They attack, take whatever strikes
their fancy, and move on. They lack the discipline required to maintain the things
they steal, so they always seek weakly defended settlements and caravans to replace
those things that break or wear out in the humidity of the jungle.
    </p>
    <p>
Occasionally an individual jungle orc questions the need for constant battle,
or seeks strategies other than brute force. These are often the more intelligent
members of their clans. Such iconoclasts often leave the jungle &mdash; at least
temporarily &mdash; to become adventurers.
    </p>
    <h5>Racial Traits</h5>
Jungle orcs have all <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="monstersOtoR.html#orc">orc racial traits</a>, with additions and exceptions as noted below.
    <ul>
      <li>Low-Light Vision: A jungle orc can see twice as far as a human in starlight,
moonlight, torchlight, and similar conditions of poor illumination. He retains
the ability to distinguish color and detail under these conditions.</li>
      <li>No darkvision.</li>
      <li>+2 racial bonus on <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#heal">Heal</a> and <a style={{
          "color": "rgb(87, 158, 182)"
        }} href="skillsAll.html#survival">Survival</a> checks: Jungle orcs must be self-sufficient
in order to survive their environment.</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      